import React, { useState, useEffect, Suspense, lazy } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Banner from "../Banner/ThumbAds";
import PageBanner from "../Banner/PageBanner";

const Recents = lazy(() => import("../Recents/Recents"));
const Trending = lazy(() => import("../Trending/Trending"));
const Promoted = lazy(() => import("../Promoted/Promoted"));

function Landing() {
  return (
    <Container>
      {/* <Row>
        <Col className="p-2 text-center">
          <b>
            This website was last updated November 2023. There are currently no
            plans to update it further. Please use the{" "}
            <Link to="/creators">Creators</Link> page to find the creators
            you're looking for.
          </b>
        </Col>
      </Row> */}
      <Suspense fallback={<div></div>}>
        <Row>
          <Container className=" text-center bg-intro-gradient  p-2 rounded-3 my-1 " >
            <h2 className='text-light'>
            Preview Hundreds of STL Miniature Bundles Every Month
            </h2>
            <h4 style={{color: 'navy'}}>
            Each month, hundreds of talented STL creators release bundles of miniatures, often at a significant discount, through platforms like Patreon and MyMiniFactory Tribes. With so many options, it can be hard to keep track of everything—until now.
            </h4>
            <h4 style={{color: 'navy'}}>
            This site brings all those monthly releases together in one place, making it easy to preview current, upcoming, and welcome packs from your favorite creators.
            </h4>
            <h5>
              <i>

              Click on a picture to see it larger and look through more images
              in the same bundle.
              </i>
            </h5>
          </Container>
        </Row>
        {/* <Row>
          <PageBanner bannerNum={1} />
        </Row> */}
        <div style={{ minHeight: "300px" }}>
          <Promoted limitAmount={9} />
        </div>
        {/* <Banner /> */}

        <Row className="mt-3">
          <hr />

          <h1 className="text-cnter">Recently added previews:</h1>
          {/* <hr /> */}
        </Row>
        <div style={{ minHeight: "1000px" }}>
          <Recents limitAmount={9} />
        </div>
        <Row>
          <Link className="text-center " to="/this-month">
            <h2>See more of this month's bundles.</h2>
          </Link>
        </Row>
        {/* <Row className="mt-4">
          <hr />
          <h1 className="text-cente">Trending Bundles</h1>
         
        </Row> */}
        {/* <div style={{ minHeight: "600px" }}>
          <Trending limitAmount={6} />
        </div>
        <h3 className="text-center my-3">
          <Link to="/trending">See more trending bundles!</Link>
        </h3> */}
      </Suspense>
    </Container>
  );
}

export default Landing;
